<template>
  <div>
    <downloadPageMb v-if="screenWidth < 765" class="is-mb" />
    <downloadPagePc v-else class="is-pc" />
  </div>
</template>

<script>
import downloadPagePc from '@/views_pc/downloadPageB/zh_tw'
import downloadPageMb from '@/views_mb/downloadPageB/zh_tw'
export default {
  components: { downloadPagePc, downloadPageMb },
  data() {
    return {
      screenWidth: document.body.clientWidth
    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  },
  watch: {
    screenWidth(val) {
      this.screenWidth = val
      // console.log(val)
    }
  },
  methods: {}
}
</script>
<style lang='scss' scoped>
.is-pc {
  display: block;
}
.is-mb {
  display: none;
}
@media (max-width: 766px) {
  .is-pc {
    display: none;
  }
  .is-mb {
    display: block;
  }
}
</style>
